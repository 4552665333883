<template>
  <div class="coaching-title">
    <a @click.prevent="back" href="#">
      <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.755092 5.86465L5.00637 10.04C5.2114 10.2414 5.54381 10.2414 5.74883 10.04L6.24467 9.553C6.44935 9.35197 6.44974 9.02616 6.24554 8.82466L2.87633 5.50004L6.24554 2.17544C6.44974 1.97394 6.44935 1.64813 6.24467 1.4471L5.74883 0.960112C5.54379 0.758739 5.21138 0.758739 5.00637 0.960112L0.755114 5.13545C0.550079 5.3368 0.550079 5.66328 0.755092 5.86465Z" fill="black"/>
      </svg>
      Retour
    </a>
    <h1 class="title-meeting">
      {{title}}
    </h1>
  </div>
</template>

<script>
export default {
  name: "TitleCoaching",
  props: ['title'],
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>