<template>
  <title-coaching title="Derniers rendez-vous"/>
  <div class="table-container meeting-list">
    <div class="left-table-container">
      <div class="anonymous-user">
        <div class="icon">
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 13.0625C12.7188 13.0625 12.1027 13.75 10 13.75C7.89732 13.75 7.28571 13.0625 6 13.0625C2.6875 13.0625 0 15.6492 0 18.8375V19.9375C0 21.0762 0.959821 22 2.14286 22H17.8571C19.0402 22 20 21.0762 20 19.9375V18.8375C20 15.6492 17.3125 13.0625 14 13.0625ZM10 12.375C13.5491 12.375 16.4286 9.60352 16.4286 6.1875C16.4286 2.77148 13.5491 0 10 0C6.45089 0 3.57143 2.77148 3.57143 6.1875C3.57143 9.60352 6.45089 12.375 10 12.375Z"
                fill="#5A72DC"/>
          </svg>
        </div>
        <div>
          <div class="spacing-bottom">
            <strong>{{ anonymousUserFirstname }}</strong>
            <p>Utilisateur anonyme</p>
          </div>
        </div>
      </div>
    </div>
    <div class="right-table-container">
      <div class="table">
        <div class="line" v-for="(meeting) in firstThreeMeetings" :key="meeting.id">
          <div class="column bold">{{ startsAtFormatedDate(meeting) }}</div>
          <div class="column">{{ meeting.subject }}</div>
          <div class="column">
            <router-link
                :to="{ name: 'MeetingDetail', params: { id: meeting.id }}">
              Voir le détail
            </router-link>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="showMore">
            <div class="line" v-for="(meeting) in moreMeetings" :key="meeting.id">
              <div class="column bold">{{ startsAtFormatedDate(meeting) }}</div>
              <div class="column">{{ meeting.subject }}</div>
              <div class="column">
                <router-link
                    :to="{ name: 'MeetingDetail', params: { id: meeting.id }}">
                  Voir le détail
                </router-link>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="actions" v-if="showMoreLink">
        <a href="#" @click="showMore = !showMore;showMoreLink = !showMoreLink">Voir plus</a>
      </div>
    </div>
  </div>
</template>

<script>
import TitleCoaching from "@/components/Coaching/TitleCoaching";
import AnonymousUsersService from "@/services/anonymousUsers.service";
import moment from "moment";

export default {
  name: "AnonymousMeetingList",
  data() {
    return {
      showMore: false,
      showMoreLink: true,
      meetings: null,
      anonymousUser: null
    }
  },
  created() {
    AnonymousUsersService.getAnonymousUser(this.$route.params.id)
        .then((response) => {
          this.anonymousUser = response.data.data.anonymous_user
        });
  },
  mounted() {
    AnonymousUsersService.getMeetingsByIdentifier(this.$route.params.id)
        .then((response) => {
          this.meetings = response.data.data.meetings
          if (response.data.data.meetings.length <= 3) {
            this.showMoreLink = false;
          }
        });
  },
  components: {
    TitleCoaching
  },
  methods: {
    startsAtFormatedDate(meeting) {
      return moment(meeting.starts_at).format('DD/MM/YYYY')
    }
  },
  computed: {
    firstThreeMeetings() {
      return this.meetings ? this.meetings.slice(0, 3) : null;
    },
    moreMeetings() {
      return this.meetings ? this.meetings.slice(3, this.meetings.length) : null;
    },
    anonymousUserFirstname() {
      return this.anonymousUser ? this.anonymousUser.first_name: null;
    }
  }
}
</script>